<template>
  <div
    id="app-template"
    class="w-full h-screen overflow-hidden flex flex-col bg-darkgrayblue-500"
  >
    <AppHeader>
      <ToolDetails />
      <AppBreadcrumbs v-if="breadcrumbs" />
      <div class="ml-auto flex gap-2">
        <slot name="header" />
        <NotificationsCenter v-if="notificationsCenter" />
        <UserDetails v-if="isLoggedIn" />
      </div>
    </AppHeader>
    <div
      class="flex overflow-hidden w-full h-full"
    >
      <AppSidebar
        :show-search="sidebarSearch"
        :show-collapse-btn="showCollapseButton"
      >
        <template #before-nav>
          <slot name="before-nav" />
        </template>
        <template #after-nav>
          <slot name="after-nav" />
        </template>
      </AppSidebar>
      <div
        :class="[
          'h-full flex-auto overflow-auto p-3 relative bg-gray-500',
          { 'rounded-tl-xl': sidebarVisible },
        ]"
      >
        <PageSpinner v-if="isLoading" />
        <slot name="page-content" />
      </div>
    </div>
    <PvConfirmDialog />
    <PvConfirmPopup group="popup" />
    <PvToast />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import localizationObj from './localization';

import AppHeader from './header/AppHeader.vue';
import AppBreadcrumbs from './header/Breadcrumbs.vue';
import AppSidebar from './sidebar/AppSidebar.vue';
import PageSpinner from './PageSpinner.vue';
import NotificationsCenter from './header/NotificationsCenter.vue';
import UserDetails from './header/UserDetails.vue';
import ToolDetails from './header/ToolDetails.vue';

export default {
  name: 'AppTemplate',
  components: {
    AppHeader,
    AppBreadcrumbs,
    AppSidebar,
    PageSpinner,
    NotificationsCenter,
    UserDetails,
    ToolDetails,
  },
  props: {
    pages: {
      type: Array,
      default: () => [],
    },
    userDetails: {
      type: Object,
      default: () => ({}),
    },
    userOptionPages: {
      type: Array,
      default: () => ([]),
    },
    noMainPage: {
      type: Boolean,
      default: false,
    },
    showSidebar: {
      type: Boolean,
      default: false,
    },
    sidebarSearch: {
      type: Boolean,
      default: false,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    notificationsCenter: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Boolean,
      default: true,
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
    toolDetails: {
      type: Object,
      default: () => ({}),
    },
    showCollapseButton: {
      type: Boolean,
      default: true,
    },
    localizationObj: {
      type: Object,
      default: () => (localizationObj),
    },
  },
  computed: {
    ...mapState('templateStore', ['isLoading', 'sidebarVisible', 'isLoggedIn']),
  },
  watch: {
    pages: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetPages', value);
      },
    },
    userDetails: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetUserDetails', value);
      },
    },
    userOptionPages: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetUserOptionPages', value);
      },
    },
    showSidebar: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetSidebarVisible', value);
      },
    },
    localizationObj: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetLocalizationObj', value);
      },
    },
    loggedIn: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetIsLoggedIn', value);
      },
    },
    noMainPage: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetNoMainPage', value);
      },
    },
    showSpinner: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetIsLoading', value);
      },
    },
    toolDetails: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetToolDetails', value);
      },
    },
  },
};
</script>

<style>
#app{
  /* TODO not sure if we will need these */
  --puzzel-grey: #32424F;
  --puzzel-primary: #EB1562;
  --puzzel-primary-light: #EE95B2;
  --sidebar-lighten: rgba(255, 255, 255, 0.1);
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* helps positioning tooltips */
}
.scrollbar-slim {
  scrollbar-width: thin;
}
.scrollbar-slim::-webkit-scrollbar {
  width: 10px;
}
.scrollbar-slim::-webkit-scrollbar-track {
  background: var(--secondary);
  border-radius: 0.25rem;
}
.scrollbar-slim::-webkit-scrollbar-thumb {
  background-color: var(--light);
  border-radius: 0.25rem;
}
.scrollbar-sidebar {
  scrollbar-width: thin;
  scrollbar-color: transparent #ffffff40;
}
.scrollbar-sidebar::-webkit-scrollbar {
  width: 10px;
}
.scrollbar-sidebar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.25rem;
}
.scrollbar-sidebar::-webkit-scrollbar-thumb {
  background-color: #ffffff40;
  border-radius: 0.25rem;
}
/* vue-json-pretty styling */
.vjs-value{
  white-space:normal;
  word-break:break-all;
}

.template-fontsize-1 { font-size: 1rem }
.template-fontsize-2 { font-size: .95rem }
.template-fontsize-3 { font-size: .80rem }
</style>
