<template>
  <nav class="absolute inset-x-0 px-2">
    <PvTieredMenu
      :model="items"
      :pt="{
        root: () => ({
          class: 'bg-transparent border-0 p-0',
        }),
        menu: () => ({
          class: 'size-10 mb-2 bg-transparent',
        }),
        submenu: () => ({
          class: 'z-[100]',
        }),
        itemContent: ({ props }) => {
          if (props.level !== 0) return { class: '' }
          // top level
          return {
            class: [
              'bg-transparent w-10 rounded-md',
              $options.baseButtonHoverClasses,
            ],
          }
        },
      }"
    >
      <template #item="{ item, props }">
        <h4
          v-if="item.parentLabel"
          class="m-0 block px-3 py-2"
        >
          {{ item.parentLabel }}
        </h4>
        <RouterLink
          v-else-if="item.to"
          v-slot="{ href, navigate, isExactActive }"
          :to="item.to"
          custom
        >
          <a
            v-ripple
            v-tooltip="item.child ? '' : item.label"
            :class="[
              'flex items-center rounded-md',
              {
                'bg-lightpurple-500 text-primary-800': item.iconOnly && isExactActive,
                'text-white': item.iconOnly && !isExactActive,
                'bg-lightpurple-500': !item.iconOnly && isExactActive,
              },
            ]"
            :href="href"
            v-bind="props.action"
            @click="navigate"
          >
            <FontAwesomeIcon
              v-if="item.icon"
              :icon="item.icon"
            />
            <span
              v-if="!item.iconOnly"
              class="ml-2"
            >{{ item.label }}</span>
          </a>
        </RouterLink>
        <a
          v-else
          v-ripple
          v-tooltip="item.label"
          :class="[
            'flex items-center rounded-md',
            {
              'bg-lightpurple-500 text-primary-800': anyChildMatching(item),
              'text-white': item.iconOnly && !anyChildMatching(item),
            },
          ]"
          v-bind="props.action"
        >
          <FontAwesomeIcon
            v-if="item.icon"
            :icon="item.icon"
          />
          <span
            v-if="!item.iconOnly"
            class="ml-2"
          >{{ item.label }}</span>
        </a>
      </template>
    </PvTieredMenu>
  </nav>
</template>
<script>

import {
  baseButtonClasses,
  baseButtonHoverClasses,
  baseTransitionClasses,
} from '../../shared/constants';

export default {
  name: 'SidebarNavigation',
  baseButtonClasses,
  baseButtonHoverClasses,
  baseTransitionClasses,
  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    items() {
      return this.pages
        .filter(({ separator }) => !separator)
        // trying to recreate the previous popover look
        .map((page) => ({
          ...page,
          iconOnly: true,
          items: page.items && [
            { parentLabel: page.label },
            { separator: true },
            ...page.items.map((e) => ({ ...e, child: true })),
          ],
        }));
    },
  },
  methods: {
    anyChildMatching(item) {
      const currentRouteName = this.$route.name;
      return item.items.some((child) => (child?.matchOn
        ? this.isMatching(child.matchOn) : child?.to?.name === currentRouteName));
    },
    isMatching(matchOnArr) {
      if (!Array.isArray(matchOnArr)) return false;
      const currentRouteName = this.$route.name;
      return matchOnArr.includes(currentRouteName);
    },
  },
};
</script>
<style scoped>
:deep(.p-tieredmenu-submenu) {
  left: 22% !important;
  top: -6px !important;
}
</style>
