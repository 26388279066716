<template>
  <nav
    class="sidebar-nav-wrap overflow-y-auto h-full"
  >
    <template
      v-for="(section, i) in pagesSplit"
      :key="i"
    >
      <p
        v-if="section.separator"
        class="block whitespace-nowrap text-white uppercase leading-[30px] border-0 border-b
          border-solid border-white my-0 text-sm"
      >
        {{ section.separator }}
      </p>
      <PvPanelMenu
        v-else
        :key="hasActiveChild(section)"
        unstyled
        :model="section"
        :pt="{
          panel: () => ({
            class: [
              $options.baseTransitionClasses,
              $options.baseButtonHoverClasses,
              'mb-1 px-3 cursor-pointer rounded-md py-1 text-white',
              'has-[.active]:bg-lightpurple-500',
              'has-[[aria-expanded=true]]:bg-lightpurple-500',
              'has-[[aria-expanded=true]]:!text-primary-800',
            ],
          }),
          rootList: () => ({
            class: 'pl-6 m-0',
          }),
        }"
      >
        <template #item="{ item }">
          <RouterLink
            v-if="item.to"
            v-slot="{ href, navigate, isExactActive }"
            :to="item.to"
            custom
          >
            <a
              :class="[linkClasses, {
                'active font-bold !text-primary-800': isExactActive || isMatching(item.matchOn),
              }]"
              :href="href"
              @click="navigate"
            >
              <FontAwesomeIcon v-if="item.icon" :icon="item.icon" class="w-5 mr-3" size="lg" />
              <span class="truncate">{{ item.label }}</span>
              <PvBadge
                v-if="item.badge"
                :value="item.badge"
                class="ml-auto rounded-md"
                severity="secondary"
              />
            </a>
          </RouterLink>
          <a
            v-else
            :class="[linkClasses, {
              'active font-bold !text-primary-800': isMatching(item.matchOn),
            }]"
            :href="item.url"
            :target="item.target"
          >
            <FontAwesomeIcon v-if="item.icon" :icon="item.icon" class="w-5 mr-3" />
            <span class="truncate">{{ item.label }}</span>
            <FontAwesomeIcon v-if="item.items" icon="angle-down" class="ml-auto w-5" />
          </a>
        </template>
      </PvPanelMenu>
    </template>
  </nav>
</template>
<script>
import {
  baseButtonClasses,
  baseButtonHoverClasses,
  baseTextClasses,
  baseTextHoverClasses,
  baseTransitionClasses,
} from '../../shared/constants';

export default {
  name: 'SidebarNavigation',
  baseButtonClasses,
  baseButtonHoverClasses,
  baseTransitionClasses,
  baseTextClasses: [baseTextClasses, baseTextHoverClasses].join(' '),
  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    linkClasses() {
      return [
        this.$options.baseTransitionClasses,
        'text-[inherit] no-underline flex cursor-pointer items-center py-2',
        baseTextHoverClasses,
      ];
    },
    hasSeparators() {
      return !!this.pages.find((page) => !!page.separator);
    },
    pagesSplit() {
      // this is needed to allow for botstudios separators
      if (!this.hasSeparators) return [this.pages];
      const result = [];
      let currentResultIndex = 0;
      for (let index = 0; index < this.pages.length; index++) {
        const item = this.pages[index];
        if (item.separator) {
          result.push(item);
          currentResultIndex = result.length;
          continue;
        }
        if (Array.isArray(result[currentResultIndex])) result[currentResultIndex].push(item);
        else result.push([item]);
      }
      return result;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    isMatching(matchOnArr) {
      if (!Array.isArray(matchOnArr)) return false;
      const currentRouteName = this.$route.name;
      return matchOnArr.includes(currentRouteName);
    },
    hasActiveChild(section) {
      if (!section) return false;
      let sectionTosMapped = section.map((s) => {
        if (s.to) return s.to;
        if (s.items) {
          return s.items.map((item) => item.to);
        }
        return { name: '' };
      });
      sectionTosMapped = sectionTosMapped.flat().map((s) => s.name);
      return !!sectionTosMapped.includes(this.currentRouteName);
    },
  },
};
</script>
<style scoped>
.sidebar-nav-wrap {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sidebar-nav-wrap::-webkit-scrollbar {
  display: none;
}
:deep(li){
  list-style-type: none;
}
</style>
