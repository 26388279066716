export const arcticHaze = {
  DEFAULT: '#fffefd',
  50: '#fffefd',
  100: '#fffbf9',
  200: '#fff9f6',
  300: '#fff7f2',
  400: '#fff5ef',
  500: '#fff3eb',
  600: '#e8ddd6',
  700: '#b5ada7',
  800: '#8c8681',
  900: '#6b6663',
};

export const danger = {
  DEFAULT: '#e3000f',
  50: '#fce6e7',
  100: '#f6b0b5',
  200: '#f28a91',
  300: '#ec545e',
  400: '#e9333f',
  500: '#e3000f',
  600: '#cf000e',
  700: '#a1000b',
  800: '#7d0008',
  900: '#5f0006',
};

export const fjordSun = {
  DEFAULT: '#ff9903',
  50: '#fff5e6',
  100: '#ffdfb1',
  200: '#ffd08b',
  300: '#ffbb56',
  400: '#ffad35',
  500: '#ff9903',
  600: '#e88b03',
  700: '#b56d02',
  800: '#8c5402',
  900: '#6b4001',
};

export const forestGreen = {
  DEFAULT: '#33c4b3',
  50: '#ebf9f7',
  100: '#c0ede7',
  200: '#a1e4dc',
  300: '#76d7cc',
  400: '#5cd0c2',
  500: '#33c4b3',
  600: '#2eb2a3',
  700: '#248b7f',
  800: '#1c6c62',
  900: '#15524b',
};

export const lavenderFrost = {
  DEFAULT: '#4E64CA',
  50: '#fbf7fe',
  100: '#f4e5fc',
  200: '#eed9fa',
  300: '#e7c7f8',
  400: '#e2bdf7',
  500: '#dbacf5',
  600: '#c79ddf',
  700: '#9b7aae',
  800: '#785f87',
  900: '#5c4867',
};

export const nordicCloud = {
  DEFAULT: '#f6f6fb',
  50: '#fefeff',
  100: '#fcfcfe',
  200: '#fbfbfd',
  300: '#f9f9fc',
  400: '#f8f8fc',
  500: '#f6f6fb',
  600: '#e0e0e4',
  700: '#afafb2',
  800: '#87878a',
  900: '#676769',
};

export const nordicDepths = {
  DEFAULT: '#0C1436',
  50: '#E7E8EB',
  100: '#B4B6C1',
  200: '#8F93A3',
  300: '#5C6278',
  400: '#3D435E',
  500: '#0C1436',
  600: '#0B1231',
  700: '#090E26',
  800: '#070B1E',
  900: '#050817',
};

export const northernThistle = {
  DEFAULT: '#623875',
  50: '#efebf1',
  100: '#cec1d4',
  200: '#b7a3c0',
  300: '#967aa3',
  400: '#816091',
  500: '#623875',
  600: '#59336a',
  700: '#462853',
  800: '#361f40',
  900: '#291831',
};

export const pineShadow = {
  DEFAULT: '',
  50: '#E6EFED',
  100: '#B0CCC8',
  200: '#8AB4AD',
  300: '#549288',
  400: '#337D71',
  500: '#005C4D',
  600: '#005446',
  700: '#004137',
  800: '#00332A',
  900: '#002720',
};

export const wildBerry = {
  DEFAULT: '#ff375b',
  50: '#ffebef',
  100: '#ffc1cc',
  200: '#ffa3b4',
  300: '#ff7991',
  400: '#ff5f7c',
  500: '#ff375b',
  600: '#e83253',
  700: '#b52741',
  800: '#8c1e32',
  900: '#6b1726',
};

// single colors
export const focusColor = '#0671e0';
