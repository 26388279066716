<template>
  <div
    :aria-invalid="isInvalid"
    :class="wrapClasses"
    role="group"
  >
    <slot name="label" />
    <label
      v-if="label"
      :class="[labelClass, { 'text-sm': labelSize === 'sm' }]"
      :for="labelFor || undefined"
    >
      {{ label }}
    </label>
    <slot />
    <SwMessage
      v-if="state === false && (invalidFeedback || invalidFeedbackSlot)"
      class="my-1"
      severity="error"
      :pt="{
        text: () => ({ class: 'text-sm' }),
      }"
    >
      <slot
        v-if="state === false"
        name="invalid-feedback"
      >
        {{ invalidFeedback }}
      </slot>
    </SwMessage>
    <SwMessage
      v-else-if="state === true && (validFeedback || validFeedbackSlot)"
      class="my-1"
      severity="success"
      :pt="{
        text: () => ({ class: 'text-sm' }),
      }"
    >
      <slot
        v-if="state === true"
        name="valid-feedback"
      >
        {{ validFeedback }}
      </slot>
    </SwMessage>
    <template v-if="stateIsVuelidate && state.$errors?.length">
      <SwMessage
        v-for="error in state.$errors"
        :key="error.$uid"
        class="my-1"
        severity="error"
        :pt="{
          text: () => ({ class: 'text-sm' }),
        }"
      >
        {{ error.$message || 'The field is invalid' }}
      </SwMessage>
    </template>
    <small v-if="description" class="text-sm text-darkgrayblue-800">{{ description }}</small>
    <small v-else-if="descriptionSlot" class="text-sm mt-1">
      <slot name="description" />
    </small>
  </div>
</template>

<script>
const propStringDefaultEmpty = {
  type: String,
  default: '',
};

export default {
  name: 'FormGroup',
  props: {
    wrapClasses: {
      type: String,
      default: 'flex flex-col mb-4',
    },
    label: propStringDefaultEmpty,
    labelFor: propStringDefaultEmpty,
    labelSize: {
      type: String,
      default: 'md',
    },
    labelClass: {
      type: String,
      default: 'mb-1',
    },
    description: propStringDefaultEmpty,
    validFeedback: propStringDefaultEmpty,
    invalidFeedback: propStringDefaultEmpty,
    state: {
      type: [Boolean, null, Object],
      default: null,
    },
    noAutoTouch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    invalidFeedbackSlot() {
      return this.$slots['invalid-feedback'];
    },
    validFeedbackSlot() {
      return this.$slots['valid-feedback'];
    },
    descriptionSlot() {
      return this.$slots.description;
    },
    stateIsVuelidate() {
      if ([null, undefined].includes(this.state)) return false;
      if (typeof this.state !== 'object') return false;
      return '$invalid' in this.state;
    },
    isInvalid() {
      if ([null, undefined].includes(this.state)) return undefined;
      if (!this.stateIsVuelidate) return this.state === false;
      return this.state.$invalid;
    },
  },
  mounted() {
    if (!this.noAutoTouch && this.stateIsVuelidate) {
      this.state.$touch();
    }
  },
  unmounted() {
    if (this.stateIsVuelidate) {
      this.state.$reset();
    }
  },
};
</script>
