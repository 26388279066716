<template>
  <PvBreadcrumb
    class="flex flex-auto"
    v-bind="{ home }"
    :model="!$route.meta.breadcrumb ? currentRoute : breadcrumbList"
    :pt="{
      root: () => ({ class: 'bg-transparent' }),
      separator: () => ({ class: 'text-lightpurple-500' }),
    }"
  >
    <template #item="{ item }">
      <span
        v-if="!($route.meta.breadcrumb ? toRoute(item.path) : item.link)"
        class="font-semibold tracking-wide"
        :class="{
          'text-white': item.highlight,
          'text-lightpurple-500': !item.highlight,
        }"
      >
        {{ !$route.meta.breadcrumb ? formatBreadcrumb(item.path) : item.display }}
      </span>
      <RouterLink
        v-else
        class="no-underline transition-all
         duration-150 font-semibold tracking-wide"
        :class="{
          'text-white': item.highlight,
          'text-lightpurple-500': !item.highlight,
        }"
        :to="!$route.meta.breadcrumb ? toRoute(item.path) : item.link"
      >
        {{ !$route.meta.breadcrumb ? formatBreadcrumb(item.path) : item.display }}
      </RouterLink>
    </template>
  </PvBreadcrumb>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppBreadcrumbs',
  computed: {
    ...mapState('templateStore', ['localizationObj', 'noMainPage']),
    currentRoute() {
      return Array.isArray(this.$route.matched)
        ? this.$route.matched
        : [];
    },
    home() {
      // add link to home page if user logged in
      if (!this.noMainPage
          && !this.$route.path.includes('login')
          && !this.$route.path.includes('logout')) {
        return {
          display: this.localizationObj.breadcrumbs.home,
          path: '/',
          link: '/', // we're adding both so either case can use the same home
        };
      }
      return null;
    },
    breadcrumbList() {
    // new version of breadcrumbs
    // if project is not updated to the new version, it will just use the old one
      if (!this.$route.meta.breadcrumb) return [];
      let crumbs = [];
      // create breadcrumbs from route.matched elements
      this.$route.matched.forEach((element) => {
        // static breadcrumb
        if (element.meta.breadcrumb.display) {
          crumbs.push(element.meta.breadcrumb);
          // dynamic breadcrumb - has some type of parameter(s)
        } else {
          const crumb = element.meta.breadcrumb(element, this.$route.params);
          // handle multiple breadcrumbs returned
          if (crumb && crumb.length) {
            crumbs.push(...crumb);
          } else {
            crumbs.push(crumb);
          }
        }
      });
      // if there's hash, fortmat and add it as the last breadcrumb
      // hashes with multiple words should be separated with -
      if (this.$route.hash) {
        const last = crumbs[crumbs.length - 1];
        let formattedHash = this.$route.hash.replace('#', '').replace('-', ' ').split(' ');
        formattedHash = formattedHash.map((e) => e[0].toUpperCase() + e.substring(1)).join(' ');
        if (formattedHash.length > 20) {
          formattedHash = `${formattedHash.slice(0, 21)}...`;
        }
        crumbs.push({
          display: formattedHash,
          link: { name: last.name, params: last.params, hash: this.$route.hash },
        });
      }
      // routes that have base component should return null instead of duplicate crumb
      // so remove the nulls
      crumbs = crumbs.filter((el) => el !== null && el.link !== null)
        .map((e) => ({
          display: e.display && e.display?.length > 30 ? `${e.display.slice(0, 31)}...` : e.display,
          link: e.link,
          fullDisplay: e.display?.length > 30 ? e.display : null,
        }));

      // mark last element so we can add the white text color to it
      if (crumbs.length) crumbs[crumbs.length - 1].highlight = true;
      return crumbs;
    },
  },
  methods: {
    toRoute(name) {
      const path = this.currentRoute.slice(0, this.currentRoute.indexOf(name)).concat(name).join('/');
      if (this.$route.path === path) return '';
      if (!path) return '';
      return `/${path}/`;
    },
    formatBreadcrumb(bread) {
      if (Object.values(this.$route.params).includes(bread)) {
        if (bread.length > 8) {
          return `${bread.substring(0, 8)}...`;
        }
        return bread;
      }
      if (Number.isNaN(Number(bread))) {
        return bread.replace(/-/g, ' ')
          .split(' ').map((word) => word[0].toUpperCase() + word.substring(1)).join(' ');
      }
      return bread;
    },
  },
};
</script>
