import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';
import {
  arcticHaze, danger, fjordSun,
  focusColor,
  forestGreen, lavenderFrost, nordicCloud, nordicDepths, northernThistle, wildBerry,
} from './puzzel-colors';

/*
  This file defines any SupWiz theme overwrites
  We use PrimeVue's Aura theme as a base. Anything in this file
  are our custom overwrites
*/

const lightBorderColor = nordicDepths[100];

const focusRing = {
  width: '3px',
  style: 'solid',
  color: focusColor,
  offset: '2px',
  shadow: 'none',
};

const inputFieldOverrides = {
  borderColor: lightBorderColor,
  placeholderColor: '{surface.300}',
};

export default definePreset(Aura, {
  primitive: {
    borderRadius: {
      none: '0',
      xs: '0.1rem',
      sm: '0.2rem',
      md: '0.2rem',
      lg: '0.5rem',
      xl: '0.75rem',
    },
    emerald: {},
    green: forestGreen,
    lime: {},
    red: danger,
    orange: {},
    amber: {},
    yellow: fjordSun,
    teal: {},
    cyan: {},
    blue: {},
    indigo: {},
    violet: {},
    purple: {},
    fuchsia: {},
    pink: wildBerry,
    rose: {},
    slate: arcticHaze,
    gray: nordicCloud,
    sky: lavenderFrost,
    zinc: {},
    neutral: nordicCloud,
    stone: arcticHaze,
  },
  semantic: {
    transitionDuration: '0.3s',
    focusRing,
    primary: northernThistle,
    formField: {
      paddingX: '0.75rem',
      paddingY: '0.5rem',
      sm: {
        fontSize: '0.875rem',
        paddingX: '0.625rem',
        paddingY: '0.375rem',
      },
      lg: {
        fontSize: '1.125rem',
        paddingX: '0.875rem',
        paddingY: '0.625rem',
      },
      borderRadius: '{border.radius.md}',
      focusRing: {
        ...focusRing,
        offset: '0px',
        width: '2px',
      },
      transitionDuration: '{transition.duration}',
    },
    colorScheme: {
      light: {
        surface: {
          ...nordicDepths,
          100: nordicDepths[50],
          200: nordicDepths[50],
        },
        text: {
          color: '{surface.500}',
          hoverColor: '{surface.700}',
          mutedColor: '{surface.300}',
          hoverMutedColor: '{surface.700}',
        },
        formField: inputFieldOverrides,
      },
    },
  },
  components: {
    button: {
      fontWeight: 500,
      colorScheme: {
        light: {
          primary: {
            hoverBackground: '{primary.800}',
            activeBackground: '{primary.800}',
            hoverBorderColor: '{primary.800}',
            activeBorderColor: '{primary.800}',
          },
          secondary: {
            color: '{primary.800}',
            background: '{gray.600}',
            borderColor: '{gray.600}',
            hoverBackground: '{gray.700}',
            activeBackground: '{gray.700}',
            hoverBorderColor: '{gray.700}',
            activeBorderColor: '{gray.700}',
            focusRingColor: '{gray.700}',
            hoverColor: '{primary.800}',
            activeColor: '{primary.800}',
          },
          info: {
            background: '{sky.800}',
            borderColor: '{sky.800}',
            hoverBackground: '{sky.900}',
            activeBackground: '{sky.900}',
            hoverBorderColor: '{sky.900}',
            activeBorderColor: '{sky.900}',
            focusRingColor: '{sky.900}',
          },
          success: {
            background: '{green.700}',
            borderColor: '{green.700}',
            hoverBackground: '{green.800}',
            activeBackground: '{green.800}',
            hoverBorderColor: '{green.800}',
            activeBorderColor: '{green.800}',
            focusRingColor: '{green.800}',
          },
          outlined: {
            secondaryHoverBackground: '{surface.100}',
            infoColor: '{sky.700}',
            successColor: '{green.700}',
          },
          text: {
            secondaryColor: '{surface.500}',
            secondaryHoverBackground: '{surface.100}',
            infoColor: '{sky.700}',
            successColor: '{green.700}',
          },
        },
      },
    },
    datatable: {
      colorScheme: {
        light: {
          borderColor: lightBorderColor,
          columnTitleFontWeight: 500,
        },
      },
    },
    inputgroup: {
      addon: {
        background: '{surface.50}',
      },
    },
    tag: {
      colorScheme: {
        light: {
          primary: {
            color: '{primary.600}',
          },
          info: {
            color: '{sky.800}',
          },
          success: {
            color: '{green.800}',
          },
        },
      },
    },
    message: {
      colorScheme: {
        light: {
          success: {
            color: '{green.800}',
          },
        },
      },
    },
  },
});
