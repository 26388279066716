export const systemTypes = {
  supchat: {
    name: 'SupChat',
  },
  bomgar: {
    name: 'Bomgar (Beyond Trust)',
  },
  genesyscloud: {
    name: 'Genesys Cloud',
  },
  zendesk: {
    name: 'Zendesk Chat Conversation',
  },
  'zendesk-support': {
    name: 'Zendesk Ticket Support',
  },
  'zendesk-sunco': {
    name: 'Zendesk Sunshine Conversation',
  },
  'zendesk-sunco-broker': {
    name: 'Zendesk Sunshine Conversation Broker',
  },
  jsonwebhook: {
    name: 'Json Webhook',
  },
  puzzel: {
    name: 'Puzzel Webhook',
  },
  'puzzel-hub': {
    name: 'Puzzel Communication Hub',
    botstudioPlatforms: ['puzzel-hub-chat', 'puzzel-hub-voice', 'puzzel-hub-some'],
  },
  'puzzel-ticket': {
    name: 'Puzzel Case Management',
  },
  vonage: {
    name: 'Vonage Voice',
  },
  'vonage-puzzel': {
    name: 'Vonage Voice (w. Puzzel login)',
  },
  'vonage-trio': {
    name: 'Vonage Voice (w. Trio login)',
  },
  'topdesk-incident': {
    name: 'TOPdesk Incident',
  },
  audiocodes: {
    name: 'AudioCodes',
  },
  'freshdesk-ticket': {
    name: 'Freshdesk Ticket',
  },
  'websocket-voice': {
    name: 'Websocket Voice',
  },
  'user-defined': {
    name: 'User Defined (Change this to desired Chat System)',
  },
};

export const voicePlatforms = [
  'audiocodes',
  'puzzel-hub',
  'puzzel-hub-voice', // BotAdmin will never see this, but is here for completeness
  'vonage',
  'vonage-puzzel',
  'vonage-trio',
];

export const mailPlatforms = [
  'puzzel-ticket',
  'topdesk-incident',
  'zendesk-support',
];

export const brokers = [
  'zendesk-sunco-broker',
];

export const supportedBotstudioPlatforms = Object.entries(systemTypes)
  .reduce((mapping, [key, value]) => ({
    ...mapping,
    [key]: value.botstudioPlatforms || [key],
  }), {});

export const statusDescriptions = {
  ONLINE: {
    title: 'Online',
    severity: 'success',
    text: 'Everything is working as expected.',
  },
  INVISIBLE: {
    title: 'Invisible',
    severity: 'warning',
    text: 'There is a connection from BotAdmin to the support system. '
      + 'However, the bot is set to a status other than online in the support system. '
      + 'The bot will not pick up any new conversations until the status is changed.',
  },
  OFFLINE: {
    title: 'Offline',
    severity: 'danger',
    text: 'There is currently no connection available to the system. This can either be '
      + 'your connection to the system, or the system\'s connection that is interrupted.',
  },
};
