export default {
  install: (app) => {
    function confirmDialogPromise(params) {
      return new Promise((resolve) => {
        app.config.globalProperties.$confirm.require({
          ...params,
          rejectProps: {
            ...params.rejectProps,
            severity: params.rejectProps?.severity ?? 'secondary',
            text: params.rejectProps?.text ?? true,
          },
          accept: () => resolve(true),
          reject: () => resolve(false),
        });
      });
    }
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$swPromiseConfirm = confirmDialogPromise;
  },
};
